import { Directive, Input } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * <ng-container [getVar]="method(...)" #variableName="getVar">
 *   {{ variableName.value }}
 *   {{ variableName.value$ | async }}
 * </ng-container>
 */
@Directive({
  selector: '[getVar]',
  exportAs: 'getVar',
})
export class GetVarDirective {

  private valueBehaviorSubject = new BehaviorSubject<any>(null);

  get value(): any {
    return this.valueBehaviorSubject.value;
  }

  get value$(): Observable<any> {
    return this.valueBehaviorSubject.asObservable();
  }

  @Input()
  set getVar(value: any) {
    this.valueBehaviorSubject.next(value);
  }

}
